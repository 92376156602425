<script>
// import { mapState } from 'vuex';

import { BIconTrash,BBadge, BIconSearch } from 'bootstrap-vue';

// import formatDate from '@/mixins/formatDate';
// import trialHelpers from '@/mixins/trialHelpers';
import TrialBanner from '../components/TrialBanner.vue';
import {hasAddPermission} from '@/mixins/roleLevelPermissions';
export default {
	name: 'clioHome',
	components: {
		BIconTrash,
		TrialBanner,
		BBadge,
		BIconSearch
	},
	data() {
		return {
		};
	},
	methods: {
		loader(value){
			console.log("isLoading: ",value)
		},
	},
	computed: {
		// ...mapState(['user','agreements', 'userProfile','subscriptions']),
		loadingAgreements() {
			// console.log('pre home post login',this.hasAddPermission('agreements'))
			return hasAddPermission('agreements');
		},
	},
	mounted() {
		// console.log('pre home post login',this.hasAddPermission('agreements'))
		// if(this.$route.query && this.$route.query.code){
			this.loader(true)
		// } else if(this.$route.query && this.$route.query.error == 'access_denied'){
			this.$router.push({ path: '/login' });
		// }

		setTimeout(() => {
			// this.$router.push({ name: 'agreementsHome' });
		}, 3000);
	}
};
</script>

<style>

.home-wrapper {
	z-index: 2;
	min-height: calc(100vh - 10rem);
}

::-webkit-search-cancel-button{
	cursor: pointer;
}

.spinner-outer{
	display: table;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
	top: 50%;
	left: 50%;
}

</style>

<template>
	<b-container class="mt-5">
		<b-card class="home-wrapper">
			<b-spinner
				style="width: 3rem; height: 3rem"
				variant="primary"
				label="Loading..."
				class="spinner-outer"
			/>
			=={{ loadingAgreements }} ++
		</b-card>
	</b-container>
</template>
